<template>
    <div class="settings">
        <edit-profile-modal @saved-user-profile="savedUserProfile" />
        <creator-program-modal />
        <div class="container">
            <div class="row">
                <div class="col col-lg-4 col-xl-3">
                    <div class="settings-menu">
                        <h3>
                            <back-button class="d-block d-md-none" />
                            Settings
                        </h3>
                        <div class="setting-category">
                            <p class="setting-category-title">
                                Account
                            </p>
                            <router-link :to="{ name: 'web-app-profile-settings-edit' }" class="d-none d-lg-flex">
                                Edit Profile <img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                            <a href="javascript:void(0)" class="d-flex d-lg-none" @click="$modal.show('edit-profile')">Edit Profile <img src="@assets/img/icons/arrow-right.svg"></a>
                            <router-link :to="{ name: 'web-app-profile-settings-blocked-accounts' }">
                                Blocked Accounts <img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                            <router-link :to="{ name: 'web-app-profile-settings-creator-program' }" class="d-none d-lg-flex">
                                Creator Program<img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                            <router-link :to="{ name: 'web-app-profile-settings-notifications' }" class="d-none d-lg-flex">
                                Notification Settings<img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                            <router-link :to="{ name: 'web-app-profile-settings-notifications-insolate' }" class="d-lg-none">
                                Notification Settings <img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                        </div>
                        <hr>
                        <div class="setting-category">
                            <p class="setting-category-title">
                                Legal and Support
                            </p>
                            <a href="mailto:support@memod.com">Help <img src="@assets/img/icons/arrow-right.svg"></a>
                            <router-link :to="{ name: 'memod-end-user-license-agreement' }">
                                End-User License Agreement <img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                            <router-link :to="{ name: 'memod-tos' }">
                                Terms of Service <img src="@assets/img/icons/arrow-right.svg">
                            </router-link>
                        </div>
                        <hr>
                        <div class="setting-category">
                            <a href="javascript:void(0)" @click="logout">Logout <img src="@assets/img/icons/arrow-right.svg"></a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Settings",
    components: {
        BackButton: () => import(/* webpackChunkname: "back-button" */ "@c/atoms/back-button"),
        EditProfileModal: () => import(/* webpackChunkName: "edit-profile-modal" */ "@/components/organisms/modals/edit-profile"),
        CreatorProgramModal: () => import(/* webpackChunkName: "creator-program" */ "@/components/organisms/modals/creator-program")
    },
    methods: {
        savedUserProfile() {
            this.$notify({
                group: "app-notifications",
                type: "success",
                text: "Your profile have been updated",
                duration: 3000
            });
        },
        async logout() {
            this.$emit("is-loading", true);

            await this.$router.push({ name: "landing" });
            await this.$store.dispatch("Application/resetGlobalData");

            window.location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
.settings {
    padding-top: 100px;

    @media(max-width: $md) {
        padding-top: 20px;
    }

    h3 {
        margin-bottom: 25px;

        @media(max-width: $md) {
            text-align: center;
            position: relative;
            margin-bottom: 45px;

            .back-button {
                position: absolute;
                height: 22px;
            }
        }
    }

    .settings-menu {
        .setting-category {
            .setting-category-title {
                margin-bottom: 20px;
                color: #B2B1B1;
                font-size: 14px;
            }

            a {
                font-size: 18px;
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 18px;

                img {
                    opacity: .5;
                    width: 10px;
                }
            }
        }

        hr {
            margin-top: 15px;
            margin-bottom: 30px;
            border-color: white;
            opacity: .5;
        }
    }
}
</style>
